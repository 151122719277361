export default {
  name: "My",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      PersonalCenterRevenueChange: {
        desc: "",
        revenue: "0",
      },
    };
  },
  methods: {
    LoggedOut() {
      this.$Api.get().Logout()
    },
    async getPersonalCenterRevenueChange() {
      await this.$axios.get("personal-center/revenue-change").then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.PersonalCenterRevenueChange = res.data;
        }
      });
    },
  },
  computed: {
    // 获取当前路由的子路由
    getMenu() {
      var routes = {
        children: this.$router.options.routes,
      };
      var route = this.$route.matched;
      for (let i in route)
        routes = routes.children.find((e) => e.name == route[i].name);
      const a = {};
      routes.children.map((item) => {
        if (item.meta.type === "menu") {
          if (!a.menu) a.menu = [];
          a.menu.push(item);
        }
        if (item.meta.type === "moreMenu") {
          if (!a.moreMenu) a.moreMenu = [];
          a.moreMenu.push(item);
        }
      });
      return {
        ...a
      };
    },
  },
  created() {
    this.getPersonalCenterRevenueChange();
  },
};
