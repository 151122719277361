
<template>
  <!-- 中间页面,防止当前组件中的方法在子页面中执行 -->
  <index-Page v-if="$route.name === 'My'" />
  <router-view v-else />
</template>

<script>
import indexPage from "./indexPage";
export default {
  components: {
    indexPage,
  },
};
</script>
